<template>
  <Dialog id="triggerAgainDlg" v-model:visible="modelValue" :closable="false"
          :modal="true"
          position="top" style="min-width: 750px; max-width: 1000px">
    <template #header>
      <div style="width: 100%">
        <h3 :style="{
            backgroundColor: preparedTriggerBody.eventType === 'G' ? 'gray' : 'rgb(226, 62, 87)',
            color: '#fff',
            textAlign: 'center'
          }" class="p-m-0 p-p-3 p-d-block p-text-uppercase p-text-bold"
            style="width: 100%"
        >
          <template v-if="preparedTriggerBody.eventType === 'G'">{{ _t('label_manual_going') }}</template>
          <template v-else>{{ _t('label_triggering') }}</template>
        </h3>
        <h3 :style="{
            backgroundColor: (preparedTriggerBody.button.bgColor ?? 'gray'),
            color:
                  preparedTriggerBody.button.bgColor === '#ffffff' ||
                  preparedTriggerBody.button.bgColor === '#fff'?
                  '#000' : '#fff'
          }" class="p-m-0 p-p-3 p-d-block"
            style="width: 100%"
        >
          <i :class="[preparedTriggerBody?.button?.icon?.name]"></i>
          {{ preparedTriggerBody.button['custom-name'] ?? preparedTriggerBody.button.alarmName }}
        </h3>
      </div>
    </template>
    <div class="p-d-flex p-flex-column p-mt-4">
      <div class="p-d-inline-flex p-flex-row p-justify-between p-mb-4" style="width: 100%">
        <div class="p-d-inline-flex p-jc-center">
          <Dropdown
              v-model="preparedTriggerBody.priority"
              :options="[0,1,2,3,4,5,6,7,8,9,10]"
              :placeholder="_t('label_message_priority')"
              style="width:100%; height: 2rem;"
          />
        </div>
      </div>
      <div class="p-d-inline-flex p-flex-row">
        <div style="margin-bottom: 30px; width: 100%;">
          <Card style="width: 100%; background-color: rgb(228, 227, 225); margin: 0;">
            <template #title>
              <h6 style="margin-bottom: 5px;"><strong>{{ _t('label_alarm_text') }}:</strong></h6>
            </template>
            <template #content>
              <div v-if="originalAlarmDialogText && originalAlarmDialogText.includes('<original>')"
                   style="padding: 0.5rem;">
                <p style="max-width:100%; margin-bottom: 0; padding: 0 0.5rem;">
                  {{ alarmDialogTextArray[0] }}
                </p>
                <Textarea
                    :value="replacedAlarmDialogText"
                    @change="() => this.$emit('replaceAlarmDialogText', event.target.value)"
                    :cols="50"
                    :placeholder="_t('label_Start_typing_here')"
                    :rows="5"
                    style="width:100%; margin-bottom: 0;"
                />
                <p style="max-width:100%; margin-bottom: 15px; padding: 0 0.5rem;">
                  {{ alarmDialogTextArray[1] }}
                </p>
              </div>
              <p v-else style="max-width:100%; margin-bottom: 15px; padding: 0.5rem;">
                {{ alarmDialogTextArray[0] }}
              </p>
            </template>
          </Card>
        </div>
      </div>
      <div class="p-grid" v-if="isAlarmPanel">
        <div class="p-col-3 stripedBg">
          <p>{{ _t('label_instruction_files') }}</p>
        </div>
        <div class="p-col-5 stripedBg">
          <Dropdown v-model="preparedTriggerBody.instructionFile"
                    :options="instructions"
                    class="p-m-0"
                    optionLabel="name"
                    optionValue="path"
                    style="width: 100%;"
          >
            <template #option="slotProps">
              <div class="p-dropdown-option">
                        <span v-if="slotProps.option.org.id != 1">
                          {{ slotProps.option.org.fullOrgPath.replace('[Total system]-', '') }} -
                        </span> {{ slotProps.option.name }}
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-col-4 stripedBg" v-if="isAlarmPanel">
          <Button :disabled="!preparedTriggerBody.instructionFile"
                  :label="_t('label_assign')"
                  class="p-button-success"
                  @click="addInstruction"
          />
          <span style="float: right"><UploadFile @uploaded="loadInstructions"/></span>
        </div>
      </div>
      <div v-if="isAlarmPanel">
        <div v-if="preparedTriggerBody.instructionFiles.length > 0" class="p-flex-row"
             style="width: 100%"
        >
          <div
              v-for="(instruction, index) in preparedTriggerBody.instructionFiles"
              :key="index"
          >
            <Button :label="instruction.split('/').pop()"
                    class="p-button-link p-button-text p-button-success"
                    @click="downloadInstruction(instruction)"
            />
            <Button class="p-button-rounded p-button-danger p-button-text"
                    icon="pi pi-times"
                    @click="deleteIntruction(instruction)"
            />
            <br/>
          </div>
        </div>
        <p v-else>
          <b>
            {{ _t('label_intruction_file_missed') }}
          </b>
        </p>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-danger" icon="pi pi-times" @click="this.$emit('onClose')"/>
      <Button :label="_t('label_Trigger')" autofocus class="p-button-success" icon="pi pi-check"
              @click="this.$emit('onSubmit')"/>
    </template>
  </Dialog>
</template>

<style>
::v-deep(#triggerAgainDlg.p-dialog .p-dialog-header) {
  padding: 0 !important;
}
</style>

<script>

import UploadFile from "@/components/UploadFile.vue";
import {downloadInstruction} from '@/api/instruction'
import {getInstructionFiles} from "@/api/attachments";

export default {
  name: 'AlarmModal',
  components: {UploadFile},
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({})
    },
    isAlarmPanel: {
      type: String,
      default: ''
    },
    originalAlarmDialogText: {
      type: String,
      default: ''
    },
    alarmDialogTextArray: {
      type: Array,
      default: () => ([])
    },
    replacedAlarmDialogText: {
      type: String,
      default: ''
    }
  },
  emits: ['onClose', 'onSubmit', 'replaceAlarmDialogText'],
  data() {
    return {
      modelValue: false,
      preparedTriggerBody: {},
      instructions: [],
    }
  },
  mounted() {
    this.modelValue = this.isOpen;
    this.preparedTriggerBody = this.data

    this.loadInstructions()
  },
  watch: {
    isOpen(value) {
      this.modelValue = value
    },
    data(value) {
      this.preparedTriggerBody = value
    }
  },
  methods: {
    downloadInstruction(instructionFile) {
      downloadInstruction(instructionFile)
    },
    deleteIntruction(instruction) {
      this.preparedTriggerBody.instructionFiles
          .splice(this.preparedTriggerBody.instructionFiles.findIndex(i => i === instruction), 1)
    },
    loadInstructions() {
      getInstructionFiles('')
          .then(res => {
            if (res) {
              this.instructions = this.getInstructionsRecursive(res.data.instructions)
              if (this.instructions.length) {
                this.instructions.sort((a, b) =>
                    a.org.fullOrgPath.localeCompare(b.org.fullOrgPath)
                    || a.name.localeCompare(b.name)
                )
              }
            }
          })
    },
    getInstructionsRecursive(instructions, collection = [], org = {}) {
      for (const instruction of instructions) {
        if (instruction.files && instruction.files.length) {
          collection.concat(this.getInstructionsRecursive(instruction.files, collection, instruction.org))
        } else {
          collection.push({...instruction, org})
        }
      }
      return collection
    },
    addInstruction() {
      if (!this.preparedTriggerBody.instructionFiles) {
        this.preparedTriggerBody.instructionFiles = [];
      }
      if (this.preparedTriggerBody.instructionFiles.indexOf(this.preparedTriggerBody.instructionFile) === -1) {
        this.preparedTriggerBody.instructionFiles.push(this.preparedTriggerBody.instructionFile);
      }
      this.preparedTriggerBody.instructionFile = null;
    },
  }
}
</script>
